import React, {useEffect, useState} from "react";
import hearts from "../assets/images/shape.svg";
const targetTime = new Date("2024-10-27").getTime();

const calculateTimeLeft = currentTime => {
	const timeBetween = targetTime - currentTime;
	const seconds = Math.floor((timeBetween / 1000) % 60);
	const minutes = Math.floor((timeBetween / (1000 * 60)) % 60);
	const hours = Math.floor((timeBetween / (1000 * 60 * 60)) % 24);
	const days = Math.floor((timeBetween / (1000 * 60 * 60 * 24)) % 7);
	const weeks = Math.floor((timeBetween / (1000 * 60 * 60 * 24 * 7)) % 4.34812);
	const months = Math.floor(timeBetween / (1000 * 60 * 60 * 24 * 30.44));
	const total_days = Math.floor(timeBetween / (1000 * 60 * 60 * 24));

	return {total_days, days, hours, minutes, seconds, timeBetween, weeks, months};
};

export const Countdown = props => {
	const t = props.t;
	const [currentTime, setCurrentTime] = useState(Date.now());
	const {total_days, weeks, months, days, hours, minutes, seconds, timeBetween} = calculateTimeLeft(
		currentTime
	);

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentTime(Date.now());
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	return (
		<>
			{timeBetween > 0 ? (
				<div className="row p-5">
					<div className="mb-2 persent_top">
						<img className="col-xl-4 col-lg-8" src={hearts} alt="" />
					</div>
					<div className="pw big fw-bold">{t("__names")}</div>
					<div className="pw small fw-thin">{t("__names_quotes")}</div>
					<div className="row my-3">
						<div className="col-xl-2 p-1 col-6 text-center ">
							<div className="bg-white shadow-lg rounded-4">
								<div className="display-3 pw fw-bold">{months}</div>
								<div className="pw">{t("__months")}</div>
							</div>
						</div>
						<div className="col-xl-2 p-1 col-6 text-center ">
							<div className="bg-white shadow-lg rounded-4">
								<div className="display-3 pw fw-bold">{weeks}</div>
								<div className="pw">{t("__weeks")}</div>
							</div>
						</div>
						<div className="col-xl-2 p-1 col-6 text-center ">
							<div className="bg-white shadow-lg rounded-4">
								<div className="display-3 pw fw-bold">{days}</div>
								<div className="pw">{t("__days")}</div>
							</div>
						</div>
						<div className="col-xl-2 p-1 col-6 text-center ">
							<div className="bg-white shadow-lg rounded-4">
								<div className="display-3 pw fw-bold">{hours}</div>
								<div className="pw">{t("__hours")}</div>
							</div>
						</div>
						<div className="col-xl-2 p-1 col-6 text-center ">
							<div className="bg-white shadow-lg rounded-4">
								<div className="display-3 pw fw-bold">{minutes}</div>
								<div className="pw">{t("__minutes")}</div>
							</div>
						</div>
						<div className="col-xl-2 p-1 col-6 text-center ">
							<div className="bg-white shadow-lg rounded-4">
								<div className="display-3 pw fw-bold">{seconds}</div>
								<div className="pw">{t("__seconds")}</div>
							</div>
						</div>
					</div>
					<div className="pw quote">
						{total_days} {t("__days_till")} 27․10․2024{" "}
					</div>
				</div>
			) : (
				<>
					<div className="mb-5">
						<img style={{width: " 350px"}} src={hearts} alt="" />
					</div>
					<div className="display-1 pw fw-bold">{t("__names")}</div>
				</>
			)}
		</>
	);
};
