import React, {useState, useEffect} from "react";
import Timeline from "./components/Timeline";
import {Countdown} from "./components/Countdown";
import YaMaps from "./components/YaMaps";
import ReactPlayer from "react-player";
import video_content from "./assets/video/video.mp4";
import ImageGallery from "./components/ImageGallery";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import lng_am from "./assets/images/armenia.png";
import lng_en from "./assets/images/united-states.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
export default function App() {
	const {t} = useTranslation();
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [lng, setLng] = useState(
		localStorage.getItem("i18nextLng") ? localStorage.getItem("i18nextLng") : "hy"
	);

	useEffect(() => {
		setLng(localStorage.getItem("i18nextLng"));
	}, [localStorage.getItem("i18nextLng")]);

	function handleClick(lang) {
		i18next.changeLanguage(lang);
	}
	return (
		<div className={`${lng.substring(0, 2)} container-fluid p-0 bg-white position-relative`}>
			<div
				className="p-3 shadow-lg position-fixed rounded-4 bg-white top-0"
				style={{right: "0", zIndex: "999"}}
			>
				<button
					className="btn btn-sm m-2 p-0 shadow-lg rounded-circle"
					onClick={() => {
						handleClick("am-AM");
					}}
				>
					<img style={{width: "50px"}} src={lng_am} alt="lng" />
				</button>
				<button
					className="btn btn-sm m-2 p-0 shadow-lg rounded-circle"
					onClick={() => {
						handleClick("en-US");
					}}
				>
					<img style={{width: "50px"}} src={lng_en} alt="lng" />
				</button>
			</div>

			<div className="row col-12 p-0 m-0 h-100 bg-white flowers" style={{height: "100vh"}}>
				<div className="col-xl-8 col-lg-7 col-12 order-2 h-100 z-1">
					<Countdown t={t} />
				</div>
				<div className="d-lg-none postition-relative">
					<Button
						variant="primary"
						className="position-fixed open_video circle"
						onClick={handleShow}
					>
						{t("__open_the_video")}
					</Button>
				</div>
				<div className="col-xl-4 col-lg-5 d-lg-block d-none p-0 col-12 z-1 rounded-4 overflow-hidden position-relative">
					<div className="rounded-4 overflow-hidden">
						<ReactPlayer
							className="rounded-4 shadow-lg overflow-hidden w-100 h-100 w-auto"
							url={video_content}
							controls={true}
							playing={true}
							loop={true}
							muted={true}
						/>
					</div>
				</div>
			</div>

			<div className="col-12 h-100 bg-white my-5 py-5">
				<div className="col-xl-4 col-lg-6 col-10 mx-auto text-center fs-3">
					<p className="fs-3">{t("__quote_1")}</p>
					<p className="fs-3">{t("__quote_2")}</p>
					<p className="fs-3">{t("__quote_3")}</p>
				</div>
			</div>

			<div className="p-2">
				<ImageGallery />
			</div>

			<div className="col-12 h-100 bg-white my-5 py-5">
				<div className="col-xl-4 col-lg-6 col-10 mx-auto text-center">
					<p className="fs-3">{t("__contacts__1")}</p>
					<ol class="list-group">
						<li class="list-group-item border-0">{t("__contacts__2")}</li>
						<li class="list-group-item border-0">{t("__contacts__3")}</li>
						<li class="list-group-item border-0">{t("__contacts__4")}</li>
					</ol>
				</div>
			</div>
			<YaMaps />

			<Modal show={show} onHide={handleClose} backdrop="static" centered keyboard={false}>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body className="p-0 m-0">
					<ReactPlayer
						className="w-100 h-100 w-auto p-0"
						url={video_content}
						controls={true}
						playing={true}
					/>
				</Modal.Body>
			</Modal>
		</div>
	);
}
