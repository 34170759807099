import React from "react";
import {
	YMaps,
	Map,
	RouteButton,
	FullscreenControl,
	GeolocationControl,
	Placemark,
} from "@pbe/react-yandex-maps";

export default function YaMaps() {
	return (
		<div className="col-12">
			<YMaps className={"w-100"}>
				<Map
					className="w-100"
					style={{height: "450px"}}
					defaultState={{
						center: [40.153984, 44.024264],
						zoom: 11,
						controls: [],
					}}
				>
					<Placemark geometry={[40.153984, 44.024264]} />
					<RouteButton options={{float: "right"}} />{" "}
					<GeolocationControl options={{float: "left"}} />
					<FullscreenControl />
				</Map>
			</YMaps>
		</div>
	);
}
