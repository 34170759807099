import gallery_item_1 from "../assets/gallery/1.jpg";
import gallery_item_2 from "../assets/gallery/2.jpg";
import gallery_item_3 from "../assets/gallery/3.jpg";
import gallery_item_4 from "../assets/gallery/4.jpg";
import gallery_item_5 from "../assets/gallery/5.jpg";
import gallery_item_6 from "../assets/gallery/6.jpg";
import gallery_item_7 from "../assets/gallery/7.jpg";
import gallery_item_8 from "../assets/gallery/8.jpg";
import gallery_item_9 from "../assets/gallery/9.jpg";

export const images = [
	{src: gallery_item_8},
	{src: gallery_item_1},
	{src: gallery_item_9},
	{src: gallery_item_7},
	{src: gallery_item_2},
	{src: gallery_item_3},
	{src: gallery_item_4},
	{src: gallery_item_5},
	{src: gallery_item_6},
];

export const getImagesWithDimensions = async images => {
	try {
		const imagesWithDimensions = await Promise.all(
			images.map(async image => {
				const img = new Image();
				img.src = image.src;
				await img.decode(); // Ensure the image is fully loaded

				return {
					src: image.src,
					width: img.width,
					height: img.height,
				};
			})
		);

		return imagesWithDimensions;
	} catch (error) {
		console.error("Error loading images:", error);
		return []; // Return empty array in case of error
	}
};
