import React, {useState, useEffect} from "react";
import Masonry from "react-responsive-masonry";
import "photoswipe/dist/photoswipe.css";
import {Gallery, Item} from "react-photoswipe-gallery";
import {getImagesWithDimensions, images} from "./Images";

export default function ImageGallery() {
	const [galleryItems, setGalleryItems] = useState([]);

	useEffect(() => {
		const fetchImages = async () => {
			const imagesWithDimensions = await getImagesWithDimensions(images);
			if (imagesWithDimensions && imagesWithDimensions.length > 0) {
				const items = imagesWithDimensions.map((image, index) => (
					<Item key={index} original={image.src} width={image.width} height={image.height}>
						{({ref, open}) => (
							<div className="masonry-item" ref={ref} onClick={open}>
								<img
									src={image.src}
									alt={`Image ${index + 1}`}
									className="masonry-image" // Add class for styling
								/>
							</div>
						)}
					</Item>
				));
				setGalleryItems(items);
			} else {
				console.error("No images found");
			}
		};

		fetchImages();
	}, []);

	return (
		<div className="masonry-grid">
			<Gallery>
				<Masonry
					breakpointCols={3}
					gutter={4}
					className="my-masonry-grid"
					columnClassName="my-masonry-grid_column"
				>
					{galleryItems}
				</Masonry>
			</Gallery>
		</div>
	);
}
